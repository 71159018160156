<template>
  <b-table
    :data-testId="testId"
    show-empty
    responsive
    striped
    class="bordered"
    :empty-text="$t('Nenhum produto adicionado')"
    :fields="fields"
    :items="items"
    details-td-class="py-0 px-1"
  >
    <template #cell(action)="row">
      <e-grid-actions
        :show-update="showUpdateAction(row.item)"
        :show-delete="showRemoveAction(row.item)"
        @update="onShowProductSidebar(row.item)"
        @delete="onRemoveProduct(row.item)"
      />
    </template>
    <template #cell(skuProduct)="row">
      <b-badge
        v-if="row.item.deliveredDate"
        variant="success"
      >
        {{ $t('Entregue') }}
      </b-badge>
      <e-product-router-link
        :sku-id="row.item.skuProduct.id"
        :product-id="row.item.skuProduct.productId"
        :product-name="row.item.skuProduct.name"
        :ean="row.item.skuProduct.ean"
        :is-kit="row.item.isKit"
      />
    </template>

    <template #row-details="row">
      <div>
        <!-- itens do kit -->
        <b-table
          v-if="row.item.kitItems.length > 0"
          show-empty
          responsive
          striped
          class="bordered mb-1"
          :fields="kitSubfields"
          :items="getKitItems(row.item.kitItems)"
        >
          <template #cell(name)="rowSku">
            <e-product-router-link
              :sku-id="rowSku.item.skuProduct.id"
              :product-id="rowSku.item.skuProduct.productId"
              :product-name="rowSku.item.skuProduct.name"
              :ean="rowSku.item.skuProduct.ean"
            />
          </template>
        </b-table>

        <!-- comodatos do item -->
        <b-table
          v-if="row.item.lendingProducts && row.item.lendingProducts.length > 0"
          show-empty
          responsive
          striped
          class="bordered mb-1"
          :fields="lendingSubfields"
          :items="row.item.lendingProducts"
        >
          <template #cell(name)="rowSku">
            <e-product-router-link
              :sku-id="rowSku.item.skuProduct.id"
              :product-id="rowSku.item.skuProduct.productId"
              :product-name="rowSku.item.skuProduct.name"
              :ean="rowSku.item.skuProduct.ean"
            />
          </template>
        </b-table>
      </div>
    </template>
  </b-table>
</template>

<script>
import { BTable, BBadge } from 'bootstrap-vue'
import { EGridActions, EProductRouterLink } from '@/views/components'

// to show details, item in `items` prop needs an attribute `_showDetails: true`
export default {
  /**
   * events:
   *    @show-sidebar-update
   *    @remove-item
   */

  components: {
    BTable,
    BBadge,
    EGridActions,
    EProductRouterLink,
  },

  props: {
    testId: {
      type: String,
      default: "saleProductTable",
    },
    items: {
      type: Array,
      required: true,
    },
    isReadOnly: {
      type: Boolean,
      required: true,
    },
    showUpdateWhenExistItem: {
      type: Boolean,
      default: true,
    },
    showRemoveWhenExistItem: {
      type: Boolean,
      default: true,
    },
    showUpdate: {
      type: Boolean,
      default: true,
    },
    showRemove: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    fields() {
      return [
        {
          label: this.$t('Ações'),
          key: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          hide: this.isReadOnly,
        },
        {
          label: this.$t('Produto'),
          key: 'skuProduct',
          thClass: 'text-center',
          tdClass: 'text-left',
          formatter: val => val.name,
        },
        {
          label: this.$t('Qtd.'),
          key: 'quantity',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '60px' },
        },
        {
          label: this.$t('Valor unitário'),
          key: 'price',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px', minWidth: '120px' },
          formatter: (val, key, item) => this.$options.filters.currency(item.priceRuleSelected?.priceInfo?.unitValue),
        },
        {
          label: this.$t('Total Desconto'),
          key: 'totalDiscount',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px', minWidth: '120px' },
          formatter: (val, key, item) => this.$options.filters.currency(item.priceRuleSelected?.priceInfo?.localDiscountTotal || 0),
        },
        {
          label: this.$t('Total'),
          key: 'total',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px', minWidth: '120px' },
          formatter: (val, key, item) => this.$options.filters.currency(item.priceRuleSelected?.priceInfo?.localNetValue || 0),
        },
      ].filter(c => !c.hide)
    },

    kitSubfields() {
      return [
        {
          label: this.$t('Produtos do kit'),
          key: 'name',
          thClass: 'text-center',
          tdClass: 'text-left',
          formatter: (val, key, item) => item.skuProduct.name,
        },
        {
          label: this.$t('Qtd.'),
          key: 'quantity',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '60px' },
        },
        {
          label: this.$t('Valor unitário'),
          key: 'price',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px', minWidth: '120px' },
          formatter: (val, key, item) => this.$options.filters.currency(item.priceRuleSelected?.priceInfo?.unitValue),
        },
        {
          label: this.$t('Total Desconto'),
          key: 'totalDiscount',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px', minWidth: '120px' },
          formatter: (val, key, item) => this.$options.filters.currency(item.priceRuleSelected?.priceInfo?.localDiscountTotal || 0),
        },
        {
          label: this.$t('Total'),
          key: 'total',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px', minWidth: '120px' },
          formatter: (val, key, item) => this.$options.filters.currency(item.priceRuleSelected?.priceInfo?.localNetValue || 0),
        },
      ]
    },

    lendingSubfields() {
      return [
        {
          label: this.$t('Produtos comodato'),
          key: 'name',
          thClass: 'text-center',
          tdClass: 'text-left',
          formatter: (val, key, item) => item.skuProduct.name,
        },
        {
          label: this.$t('Qtd.'),
          key: 'quantity',
          thClass: 'text-center',
          tdClass: 'text-center',
          thStyle: { width: '60px' },
        },
        {
          label: this.$t('Valor unitário'),
          key: 'price',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px', minWidth: '120px' },
          formatter: (val, key, item) => this.$options.filters.currency(item.priceRuleSelected?.priceInfo?.unitValue),
        },
        {
          label: this.$t('Total Desconto'),
          key: 'totalDiscount',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px', minWidth: '120px' },
          formatter: (val, key, item) => this.$options.filters.currency(item.priceRuleSelected?.priceInfo?.localDiscountTotal || 0),
        },
        {
          label: this.$t('Total'),
          key: 'total',
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: { width: '120px', minWidth: '120px' },
          formatter: (val, key, item) => this.$options.filters.currency(item.priceRuleSelected?.priceInfo?.localNetValue),
        },
      ]
    },
  },

  methods: {
    showUpdateAction(item) {
      if (item.deliveredDate) {
        return false
      }

      const itemExist = item.id
      if (!this.showUpdateWhenExistItem && itemExist) {
        return false
      }

      return this.showUpdate
    },

    getKitItems(kitItems) {
      if (!kitItems) return []

      const result = kitItems.flatMap(kitItem => {
        if (kitItem?.lendingProducts?.length > 0) {
          return [kitItem, kitItem.lendingProducts[0]]
        }

        return [kitItem]
      })

      return result
    },

    showRemoveAction(item) {
      if (item.deliveredDate) {
        return false
      }

      const itemExist = item.id
      if (!this.showRemoveWhenExistItem && itemExist) {
        return false
      }

      return this.showRemove
    },

    onShowProductSidebar(item) {
      this.$emit('show-sidebar-update', item)
    },

    onRemoveProduct(item) {
      this.$emit('remove-item', item)
    },
  },
}
</script>

<style lang="scss" scoped></style>
