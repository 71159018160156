var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("e-sidebar", {
    attrs: {
      id: "sidebar-form-estimate-product",
      title: _vm.isEdit
        ? _vm.$t("Editar produto")
        : _vm.$t("Adicionar produto"),
      show: _vm.showSidebar,
      fetching: _vm.fetching,
      saving: _vm.saving,
      width: "600px",
    },
    on: { save: _vm.onSaveItem, hidden: _vm.hide },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "FormulateForm",
              { ref: "itemSidebarForm", attrs: { name: "itemSidebarForm" } },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c("e-search-sku", {
                          attrs: {
                            id: "sale_product_sidebar-sku_id",
                            "input-id": "sale_product_sidebar-sku_product",
                            "value-is-object": "",
                            "store-id": _vm.storeId,
                            "price-table-id": _vm.priceTableId,
                            disabled: _vm.isEdit,
                            autofocus: "",
                          },
                          on: { input: _vm.onSelectProduct },
                          model: {
                            value: _vm.itemForm.skuProduct,
                            callback: function ($$v) {
                              _vm.$set(_vm.itemForm, "skuProduct", $$v)
                            },
                            expression: "itemForm.skuProduct",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { md: "4" } },
                      [
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "sale_product_sidebar-quantity",
                            type: "text-number",
                            label: _vm.$t("Quantidade"),
                            validation: "required|min:1",
                          },
                          on: { input: _vm.updateInfos },
                          model: {
                            value: _vm.itemForm.quantity,
                            callback: function ($$v) {
                              _vm.$set(_vm.itemForm, "quantity", _vm._n($$v))
                            },
                            expression: "itemForm.quantity",
                          },
                        }),
                      ],
                      1
                    ),
                    !_vm.isKit
                      ? [
                          _c(
                            "b-col",
                            { attrs: { md: "4" } },
                            [
                              _c("e-slot-authenticated", {
                                attrs: {
                                  "delegable-permission":
                                    _vm.unitDiscountPermission,
                                  "store-id": _vm.storeId,
                                  "is-valid-store": !!_vm.storeId,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "content",
                                      fn: function (slotProps) {
                                        var _obj
                                        return [
                                          _c("FormulateInput", {
                                            attrs: {
                                              id: "discount-type",
                                              type: "radio",
                                              options:
                                                ((_obj = {}),
                                                (_obj[
                                                  _vm.discountTypesEnum.VALUE
                                                ] = "R$"),
                                                (_obj[
                                                  _vm.discountTypesEnum.PERCENTAGE
                                                ] = "%"),
                                                _obj),
                                              label: _vm.$t("Desconto em"),
                                              "element-class": [
                                                "d-flex",
                                                "mt-1",
                                              ],
                                              disabled: slotProps.isReadOnly,
                                            },
                                            model: {
                                              value: _vm.itemForm.discountType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.itemForm,
                                                  "discountType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "itemForm.discountType",
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  726945476
                                ),
                                model: {
                                  value: _vm.itemForm.unitDiscountAuth,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.itemForm,
                                      "unitDiscountAuth",
                                      $$v
                                    )
                                  },
                                  expression: "itemForm.unitDiscountAuth",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { md: "4" } },
                            [
                              _c("e-slot-authenticated", {
                                attrs: {
                                  "delegable-permission":
                                    _vm.unitDiscountPermission,
                                  "store-id": _vm.storeId,
                                  "is-valid-store": !!_vm.storeId,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "content",
                                      fn: function (slotProps) {
                                        return [
                                          slotProps.isReadOnly
                                            ? _c("FormulateInput", {
                                                attrs: {
                                                  id: "sale_product_sidebar-discount",
                                                  name: "discount_percentage",
                                                  type: "label",
                                                  label:
                                                    _vm.$t("Desconto") +
                                                    " (" +
                                                    _vm.getDiscountSymbol +
                                                    ")",
                                                  filter: _vm.isDiscountPercent
                                                    ? "percentage"
                                                    : "currency",
                                                  instruction:
                                                    _vm.$t(
                                                      "Desconto do pedido"
                                                    ),
                                                },
                                                model: {
                                                  value:
                                                    _vm.itemForm.unitDiscount,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.itemForm,
                                                      "unitDiscount",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "itemForm.unitDiscount",
                                                },
                                              })
                                            : _c("FormulateInput", {
                                                staticClass: "required",
                                                attrs: {
                                                  id: "sale_product_sidebar-discount",
                                                  name: "discount_percentage",
                                                  type: _vm.isDiscountPercent
                                                    ? "text-percetage"
                                                    : "text-number",
                                                  label:
                                                    _vm.$t("Desconto Un.") +
                                                    " (" +
                                                    _vm.getDiscountSymbol +
                                                    ")",
                                                  currency:
                                                    _vm.getDiscountSymbol,
                                                  "currency-symbol-position":
                                                    _vm.isDiscountPercent
                                                      ? "suffix"
                                                      : "prefix",
                                                  precision: 2,
                                                  instruction: _vm.$t(
                                                    "Desconto de 1 unidade"
                                                  ),
                                                  validation: "required",
                                                },
                                                on: { input: _vm.updateInfos },
                                                model: {
                                                  value:
                                                    _vm.itemForm.unitDiscount,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.itemForm,
                                                      "unitDiscount",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "itemForm.unitDiscount",
                                                },
                                              }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2316901765
                                ),
                                model: {
                                  value: _vm.itemForm.unitDiscountAuth,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.itemForm,
                                      "unitDiscountAuth",
                                      $$v
                                    )
                                  },
                                  expression: "itemForm.unitDiscountAuth",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _c(
                  "b-row",
                  [
                    _c("b-col", [
                      _c("div", [
                        _c("p", { staticClass: "h6 mb-0" }, [
                          _vm._v(" " + _vm._s(_vm.$t("Preço unitário")) + " "),
                        ]),
                        _c(
                          "p",
                          {
                            staticClass: "h4 text-bold",
                            attrs: {
                              "data-testid": "productSidebar-infoUnitValue",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.infoValues.productPrice
                                  )
                                ) +
                                " "
                            ),
                            _c(
                              "span",
                              {
                                staticClass: "h5",
                                attrs: {
                                  "data-testid": "productSidebar-infoQuantity",
                                },
                              },
                              [
                                _vm._v(
                                  " x" + _vm._s(_vm.itemForm.quantity) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    !_vm.isKit
                      ? _c("b-col", [
                          _vm.hasPriceTableDiscount
                            ? _c("div", [
                                _c("p", { staticClass: "h6 mb-0" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("Desconto da tabela de preço")
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c(
                                  "p",
                                  {
                                    staticClass: "h4 text-bold",
                                    attrs: {
                                      "data-testid":
                                        "productSidebar-infoDiscountPriceTable",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("currency")(
                                            _vm.infoValues
                                              .priceTableDiscountTotal
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _c("div", [
                            _c("p", { staticClass: "h6 mb-0" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("Desconto total")) + " "
                              ),
                            ]),
                            _c(
                              "p",
                              {
                                staticClass: "h4 text-bold",
                                attrs: {
                                  "data-testid":
                                    "productSidebar-infoTotalDiscount",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.infoValues.totalDiscount
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("b-col", [
                      _c("div", [
                        _c("p", { staticClass: "h6 mb-0" }, [
                          _vm._v(" " + _vm._s(_vm.$t("Total")) + " "),
                        ]),
                        _c(
                          "p",
                          {
                            staticClass: "h4 text-bold",
                            attrs: {
                              "data-testid": "productSidebar-infoTotalNetValue",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("currency")(_vm.infoValues.total)
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mt-1" },
                  [
                    _c("b-col", [
                      _c("div", [
                        _c("p", { staticClass: "h6 mb-0" }, [
                          _vm._v(
                            " " + _vm._s(_vm.$t("Quant. em estoque")) + " "
                          ),
                        ]),
                        _c(
                          "p",
                          {
                            staticClass: "h4 text-bold",
                            class: { "text-danger": _vm.infoValues.stock < 0 },
                            attrs: {
                              "data-testid": "productSidebar-infoStock",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.infoValues.stock === 0
                                    ? 0
                                    : _vm.infoValues.stock || "-"
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm.infoValues.promotionId
                      ? _c("b-col", [
                          _c("div", [
                            _c("p", { staticClass: "h6 mb-0" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$t("Quant. em promoção")) + " "
                              ),
                            ]),
                            _c(
                              "p",
                              {
                                staticClass: "h4 text-bold",
                                attrs: {
                                  "data-testid":
                                    "productSidebar-infoPromotionQuantityAvailable",
                                },
                              },
                              [
                                _vm.infoValues.promotionQuantityUnlimited
                                  ? _c("span", [
                                      _vm._v(
                                        " " + _vm._s(_vm.$t("Ilimitado")) + " "
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.getPromotionQuantityAvailable
                                          ) +
                                          " "
                                      ),
                                    ]),
                              ]
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }