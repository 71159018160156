<template>
  <e-sidebar
    id="sidebar-form-estimate-product"
    :title="isEdit ? $t('Editar produto') : $t('Adicionar produto')"
    :show="showSidebar"
    :fetching="fetching"
    :saving="saving"
    width="600px"
    @save="onSaveItem"
    @hidden="hide"
  >
    <template #content>
      <FormulateForm
        ref="itemSidebarForm"
        name="itemSidebarForm"
      >
        <b-row>
          <b-col>
            <e-search-sku
              id="sale_product_sidebar-sku_id"
              v-model="itemForm.skuProduct"
              input-id="sale_product_sidebar-sku_product"
              value-is-object
              :store-id="storeId"
              :price-table-id="priceTableId"
              :disabled="isEdit"
              autofocus
              @input="onSelectProduct"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col md="4">
            <FormulateInput
              id="sale_product_sidebar-quantity"
              v-model.number="itemForm.quantity"
              type="text-number"
              class="required"
              :label="$t('Quantidade')"
              validation="required|min:1"
              @input="updateInfos"
            />
          </b-col>
          <template v-if="!isKit">
            <b-col md="4">
              <e-slot-authenticated
                v-model="itemForm.unitDiscountAuth"
                :delegable-permission="unitDiscountPermission"
                :store-id="storeId"
                :is-valid-store="!!storeId"
              >
                <template #content="slotProps">
                  <FormulateInput
                    id="discount-type"
                    v-model="itemForm.discountType"
                    type="radio"
                    :options="{
                      [discountTypesEnum.VALUE]: 'R$',
                      [discountTypesEnum.PERCENTAGE]: '%',
                    }"
                    :label="$t('Desconto em')"
                    :element-class="['d-flex', 'mt-1']"
                    :disabled="slotProps.isReadOnly"
                  />
                </template>
              </e-slot-authenticated>
            </b-col>

            <b-col md="4">
              <e-slot-authenticated
                v-model="itemForm.unitDiscountAuth"
                :delegable-permission="unitDiscountPermission"
                :store-id="storeId"
                :is-valid-store="!!storeId"
              >
                <template #content="slotProps">
                  <FormulateInput
                    v-if="slotProps.isReadOnly"
                    id="sale_product_sidebar-discount"
                    v-model="itemForm.unitDiscount"
                    name="discount_percentage"
                    type="label"
                    :label="`${$t('Desconto')} (${getDiscountSymbol})`"
                    :filter="isDiscountPercent ? 'percentage' : 'currency'"
                    :instruction="$t('Desconto do pedido')"
                  />
                  <FormulateInput
                    v-else
                    id="sale_product_sidebar-discount"
                    v-model="itemForm.unitDiscount"
                    name="discount_percentage"
                    :type="isDiscountPercent ? 'text-percetage' : 'text-number'"
                    :label="`${$t('Desconto Un.')} (${getDiscountSymbol})`"
                    :currency="getDiscountSymbol"
                    :currency-symbol-position="isDiscountPercent ? 'suffix' : 'prefix'"
                    :precision="2"
                    :instruction="$t('Desconto de 1 unidade')"
                    validation="required"
                    class="required"
                    @input="updateInfos"
                  />
                </template>
              </e-slot-authenticated>
            </b-col>
          </template>
        </b-row>

        <b-row>
          <b-col>
            <div>
              <p class="h6 mb-0">
                {{ $t('Preço unitário') }}
              </p>
              <p
                class="h4 text-bold"
                data-testid="productSidebar-infoUnitValue"
              >
                {{ infoValues.productPrice | currency }}
                <span
                  class="h5"
                  data-testid="productSidebar-infoQuantity"
                > x{{ itemForm.quantity }} </span>
              </p>
            </div>
          </b-col>
          <b-col v-if="!isKit">
            <div v-if="hasPriceTableDiscount">
              <p class="h6 mb-0">
                {{ $t('Desconto da tabela de preço') }}
              </p>
              <p
                class="h4 text-bold"
                data-testid="productSidebar-infoDiscountPriceTable"
              >
                {{ infoValues.priceTableDiscountTotal | currency }}
              </p>
            </div>

            <div>
              <p class="h6 mb-0">
                {{ $t('Desconto total') }}
              </p>
              <p
                class="h4 text-bold"
                data-testid="productSidebar-infoTotalDiscount"
              >
                {{ infoValues.totalDiscount | currency }}
              </p>
            </div>
          </b-col>
          <b-col>
            <div>
              <p class="h6 mb-0">
                {{ $t('Total') }}
              </p>
              <p
                class="h4 text-bold"
                data-testid="productSidebar-infoTotalNetValue"
              >
                {{ infoValues.total | currency }}
              </p>
            </div>
          </b-col>
        </b-row>

        <b-row class="mt-1">
          <b-col>
            <div>
              <p class="h6 mb-0">
                {{ $t('Quant. em estoque') }}
              </p>
              <p
                class="h4 text-bold"
                :class="{ 'text-danger': infoValues.stock < 0 }"
                data-testid="productSidebar-infoStock"
              >
                {{ infoValues.stock === 0 ? 0 : infoValues.stock || '-' }}
              </p>
            </div>
          </b-col>
          <b-col v-if="infoValues.promotionId">
            <div>
              <p class="h6 mb-0">
                {{ $t('Quant. em promoção') }}
              </p>
              <p
                class="h4 text-bold"
                data-testid="productSidebar-infoPromotionQuantityAvailable"
              >
                <span v-if="infoValues.promotionQuantityUnlimited">
                  {{ $t('Ilimitado') }}
                </span>
                <span v-else>
                  {{ getPromotionQuantityAvailable }}
                </span>
              </p>
            </div>
          </b-col>
        </b-row>
      </FormulateForm>
    </template>
  </e-sidebar>
</template>

<script>
import _ from 'lodash'
import { BRow, BCol } from 'bootstrap-vue'
import ESidebar from '@/views/components/ESidebar.vue'
import { authorizationRules, discountTypes, formulateHelper, payBoxUtils, saleItemUtils } from '@/mixins'
import ESearchSku from '@/views/components/inputs/ESearchSku.vue'
import ESlotAuthenticated from '@/views/components/ESlotAuthenticated.vue'
import delegablePermissions from '@/utils/delegable-permissions'
import { getInitialSaleItem } from '@/mixins/utils/sale-item-utils'

const getSidebarForm = () => ({
  quantity: 1,
  skuProduct: null,
  unitDiscountAuth: null,
  discountType: discountTypes.computed.discountTypesEnum().VALUE,
  unitDiscount: 0,
})

const getInitialInfoValues = () => ({
  productPrice: 0,
  subtotal: 0,
  total: 0,
  totalDiscount: 0,
  priceTableDiscountTotal: 0,
  stock: null,
  promotionId: null,
  promotionQuantityAvailable: 0,
  promotionQuantityUnlimited: false,
  priceRuleSelected: null
})

export default {
  components: { BRow, BCol, ESidebar, ESearchSku, ESlotAuthenticated },

  mixins: [discountTypes, formulateHelper, authorizationRules],

  props: {
    storeId: {
      type: [String, Number],
      required: true,
    },
    priceTableId: {
      type: [String, Number],
      default: '',
    },
    itemsAdded: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      showSidebar: false,
      fetching: false,
      saving: false,
      itemForm: getSidebarForm(),
      infoValues: getInitialInfoValues(),
      saleItem: getInitialSaleItem(),
      delegateUserData: null,
      dataByEan: null
    }
  },

  computed: {
    isDiscountPercent() {
      return this.itemForm.discountType === this.discountTypesEnum.PERCENTAGE
    },

    getDiscountSymbol() {
      return this.isDiscountPercent ? this.$t('%') : this.$t('R$')
    },

    getPromotionQuantityAvailable() {
      return this.infoValues.promotionQuantityAvailable <= 0
        ? this.$t('Esgotado')
        : this.infoValues.promotionQuantityAvailable || '-'
    },

    isEdit() {
      return !!this.saleItem.id || !!this.saleItem.localId
    },

    isKit() {
      return this.saleItem?.kitItems?.length > 0
    },

    unitDiscountPermission() {
      return delegablePermissions.ERP_ORDER_ITEM_DISCOUNT
    },

    hasPriceTableDiscount() {
      return payBoxUtils.canApplyPriceTableDiscount(this.saleItem.priceRuleSelected)
    },
  },

  methods: {
    async show(item) {
      this.cleanSidebar()
      this.showSidebar = true

      if (item) {
        try {
          this.fetching = true
          await new Promise(resolve => setTimeout(() => resolve(), 200))

          this.itemForm = {
            ...getSidebarForm(),
            quantity: item.quantity,
            skuProduct: item.skuProduct,
            unitDiscountAuth: item.unitDiscountAuth,
            discountType: item.discountType || this.discountTypesEnum.VALUE,
            unitDiscount: item.unitDiscount,
          }
          this.saleItem = { ...item }

          await this.fetchSkuStockInfo()
          this.updateInfos()
        } finally {
          this.fetching = false
        }
      }
    },

    hide() {
      this.cleanSidebar()
      this.showSidebar = false
    },

    cleanSidebar() {
      this.itemForm = getSidebarForm()
      this.saleItem = getInitialSaleItem()
      this.infoValues = getInitialInfoValues()
    },

    async onSaveItem() {
      try {
        this.saving = true
        this.$refs.itemSidebarForm.showErrors()
        if (this.$refs.itemSidebarForm.hasErrors) {
          this.showInvalidDataMessage()
          return
        }

        const saleItemData = {
          ...this.saleItem,
          quantity: this.itemForm.quantity,
          unitDiscountAuth: this.itemForm.unitDiscountAuth,
          discountType: this.itemForm.discountType,
          unitDiscount: this.itemForm.unitDiscount,
        }

        if (this.isEdit) {
          this.$emit('update', saleItemData)
        } else {
          this.$emit('add', saleItemData)
        }

        this.hide()
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },

    async fetchData(ean) {
      const data = await this.$http.get(
        `/api/sales/pay-box-sku/store/${this.storeId}/ean/${ean}`,
        { params: { priceTableId: this.priceTableId } }
      )

      return data
    },

    async onSelectProduct(productSearched) {
      if (!productSearched) return
      try {
        this.saving = true

        if (!productSearched) return

        const { ean } = productSearched
        const { data } = await this.fetchData(ean)

        if (data?.stock <= 0) {
          await this.confirm({
            title: this.$t('Produto sem estoque'),
            text: this.$t(`Este produto não pode ser adicionado pois está sem estoque.`),
            cancelButtonText: this.$t('Continuar'),
            showCancelButton: true,
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: true,
            focusCancel: true,
          })
          this.cleanSidebar()
          this.focusInput('#sale_product_sidebar-sku_product')
          return
        }

        if (data.promotionId && !data.promotionQuantityUnlimited) {
          const localPromotionQuantityAdded = (this.itemsAdded || [])
            .filter(itemAdded => (
              itemAdded.localId &&
              itemAdded.skuProduct.skuId === data.skuId &&
              itemAdded.skuProduct.kit === data.kit &&
              itemAdded.skuProduct.promotionId === data.promotionId
            ))
            .reduce((total, item) => total + (item.quantity || 0), 0)

          data.promotionQuantityAvailable -= localPromotionQuantityAdded
          if ((data.promotionQuantityAvailable - localPromotionQuantityAdded) < 0) {
            data.promotionId = null
          }
        }

        this.saleItem = saleItemUtils.buildProductToSaleItem(data, {
          quantity: this.itemForm.quantity,
          unitDiscount: this.itemForm.unitDiscount,
          discountType: this.itemForm.discountType,
        })

        this.focusInput('#sale_product_sidebar-quantity')
        this.updateInfos()
      } catch (error) {
        this.itemForm.skuProduct = null
        this.saleItem = getInitialSaleItem()
        this.infoValues = getInitialInfoValues()
        this.showError({ error })
        this.focusInput('#sale_product_sidebar-sku_product', false)
      } finally {
        this.saving = false
      }
    },

    // eslint-disable-next-line func-names
    updateInfos: _.debounce(function () {
      if (!this.saleItem?.skuProduct) return

      this.saleItem = saleItemUtils.updateSaleItemOrSaleKit(this.saleItem, {
        quantity: this.itemForm.quantity,
        unitDiscount: this.itemForm.unitDiscount,
        discountType: this.itemForm.discountType,
      })

      const { priceRuleSelected } = this.saleItem

      const { stock, promotionQuantityAvailable, promotionQuantityUnlimited } =
        this.saleItem?.skuProduct || {}

      const promotionAvailable = this.saleItem.id
        ? (promotionQuantityAvailable ?? 0) + (this.saleItem.maxQuantity ?? 0)
        : promotionQuantityAvailable ?? 0

      const { priceInfo } = priceRuleSelected

      this.infoValues = {
        productPrice: priceInfo.unitValue || 0,
        stock: stock ?? null,

        promotionId: priceRuleSelected.promotion?.id,
        promotionQuantityAvailable: promotionAvailable,
        promotionQuantityUnlimited: promotionQuantityUnlimited ?? false,

        subtotal: priceInfo.localGrossValue,
        totalDiscount: priceInfo.localDiscountTotal,
        total: priceInfo.localNetValue,
        priceTableDiscountTotal: priceInfo.localPriceTableDiscountTotal,
        priceRuleSelected,
      }
    }, 400),

    async fetchSkuStockInfo() {
      const ean = this.saleItem?.skuProduct?.ean
      const { data } = await this.$http.get(
        `/api/sales/pay-box-sku/store/${this.storeId}/ean/${ean}`,
        { params: { priceTableId: this.priceTableId } }
      )

      this.saleItem.skuProduct.stock = data.stock
      this.saleItem.skuProduct.tieredPrices = data.tieredPrices

      if (this.saleItem.id) {
        const isToRefreshPromotionData =
          this.saleItem.promotionId &&
          data.promotionId &&
          this.saleItem.promotionId === data.promotionId
        if (isToRefreshPromotionData) {
          const promotionData = {
            promotionId: data.promotionId,
            priceFrom: data.priceFrom,
            promotionQuantityAvailable: data.promotionQuantityAvailable,
            promotionQuantityUnlimited: data.promotionQuantityUnlimited,
          }

          this.saleItem.skuProduct = {
            ...this.saleItem.skuProduct,
            ...promotionData,
          }
        }
      }

      const { priceRules } = payBoxUtils.buildPriceRulesFromSaleItem(this.saleItem)
      this.saleItem.priceRules = priceRules

      this.updateInfos()
    },
  },
}
</script>

<style lang="scss" scoped>
.text-bold {
  font-weight: 800;
}
</style>
